export const borderRadius3 = "3px";
export const borderRadius5 = "5px";
export const borderRadius9 = "9px";
export const borderRadius11 = "11px";
export const borderRadius15  = "15px";
export const borderRadius18 = "18px";
export const borderRadius22 = "22px";
export const borderRadius30 = "30px";
export const borderRadius40 = "40px";
export const borderRadius50 = "50px";
export const borderRadius50percent = "50%";

export const borderRadiusSmall = "9px";

const borderRadiusBig = "22px";

export const bgBlack = "black";